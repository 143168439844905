import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import omit from 'lodash/omit';

import { httpClient } from '@/core/services/http-client';
import { store } from '@/store';
import { getEnv, CommonError, formatDateToISOString, DateType } from '@/common/utils';
import {
  GalleryApplicationsDictionaryVM,
  type GalleryApplicationsListResponse,
  GalleryListElementVM,
  type GalleryListRequest,
  type GalleryListResponse,
  GalleryListVM,
  GallerySourceDictionaryVM,
  type GallerySourceListResponse,
  GalleryStatusDictionaryVM,
  type GalleryStatusListResponse,
} from '@/models/gallery';
import { YesNoFilterValues } from '@/common/constants/filter-constants';

const config = getEnv();
const MEDIA_ACCESS_URL = `${config.REACT_APP_API_URL}/media-access-api/v1/admin`;
const MEDIA_ACCESS_URL_V3 = `${config.REACT_APP_API_URL}/media-access-api/v3/admin`;
const GALLERY_LIST_ENDPOINT = `${MEDIA_ACCESS_URL_V3}/media`;
const GALLERY_STATUS_ENDPOINT = `${MEDIA_ACCESS_URL}/validation-statuses`;
const APPLICATION_STATUS_ENDPOINT = `${MEDIA_ACCESS_URL}/applications`;
const SOURCE_ENDPOINT = `${MEDIA_ACCESS_URL}/target-types`;
const MEDIA_ENDPOINT = `${MEDIA_ACCESS_URL}/media`;

export const getGalleryListData = (payload: GalleryListRequest) => {
  const booleanHelper = (value: string[]) => {
    if (value.length !== 1) {
      return undefined;
    }

    return value[0] === YesNoFilterValues.YES;
  };

  const { sources, ...filterValues } = payload;

  const gallerySourceDictionary = store.getState().gallery.source.data?.list;
  const gallerySourceDictionaryValues = gallerySourceDictionary?.map(source => source.value.toString()) || ['ROUTE', 'RATING'];

  const param = {
    ...filterValues,
    dateFrom: payload.dateFrom ? formatDateToISOString(payload.dateFrom, DateType.FROM) : undefined,
    dateTo: payload.dateTo ? formatDateToISOString(payload.dateTo, DateType.TO) : undefined,
    targetTypes: sources === undefined || sources.length === 0 ? gallerySourceDictionaryValues : (sources as string[]),
  };

  param.reported && booleanHelper(param.reported);
  const pageable = `?size=${payload.size}&page=${payload.page}`;

  return httpClient()
    .authorized.post<GalleryListResponse>(`${GALLERY_LIST_ENDPOINT}${pageable}`, {
      ...omit(param, ['name']),
      reported: param.reported ? booleanHelper(param.reported) : undefined,
      reviewed: param.reviewed ? booleanHelper(param.reviewed) : undefined,
    })
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new GalleryListVM({ data, VM: GalleryListElementVM });
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );
};

export const getGalleryStatusData = () =>
  httpClient()
    .authorized.get<GalleryStatusListResponse>(GALLERY_STATUS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) return new GalleryStatusDictionaryVM(data);
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getGalleryApplicationsData = () =>
  httpClient()
    .authorized.get<GalleryApplicationsListResponse>(APPLICATION_STATUS_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) return new GalleryApplicationsDictionaryVM(data);
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const getGallerySourceData = () =>
  httpClient()
    .authorized.get<GallerySourceListResponse>(SOURCE_ENDPOINT)
    .pipe(
      map(({ data, status }) => {
        if (status === 200 && data !== undefined) {
          return new GallerySourceDictionaryVM(data);
        }
        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const deleteMedia = (mediaId: string) =>
  httpClient()
    .authorized.delete<undefined>(`${MEDIA_ENDPOINT}/${mediaId}`)
    .pipe(
      map(({ status }) => {
        if (status === 200) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError({ code: '500', message: e })))
    );

export const updateMediaReviewStatus = (mediaId: string) =>
  httpClient()
    .authorized.patch<undefined>(`${MEDIA_ENDPOINT}/${mediaId}/review`)
    .pipe(
      map(response => {
        if ([200, 201, 202].includes(response.status)) {
          return undefined;
        }

        throw undefined;
      }),
      catchError(e => of(new CommonError(e.data)))
    );
